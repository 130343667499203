(function(kassoFactory) {
    if (typeof define === "function" && define.amd) {
        define(["jquery", "jquery-cookie"], kassoFactory);
    } else {
        window.$KASSO = kassoFactory(jQuery);
    }
}(function(jq) {

    var seconds = function(num) {
        return num * 1000;
    };

    var minutes = function(num) {
        return num * seconds(60);
    };

    var hours = function(num) {
        return num * minutes(60);
    };

    var days = function(num) {
        return num * hours(24);
    };

    var years = function(num) {
        return num * days(365);
    };

    var createDate = function(offset) {
        return new Date(new Date().getTime() + (offset || 0));
    };

    var createEventDefinition = function(eventName, eventFunction) {
        return {
            "eventName": eventName,
            "eventFunction": eventFunction
        };
    };

    var forEach = function(theCollection, theFunction) {
        if (theCollection instanceof Array) {
            for (var ix = 0, len = theCollection.length; ix < len; ix++) {
                theFunction(theCollection[ix]);
            }
        } else {
            for (var ic in theCollection) {
                if (theCollection.hasOwnProperty(ic)) {
                    theFunction({ "key": ic, "value": theCollection[ic] });
                }
            }
        }
    };

    var filter = function(theCollection, theFunction) {
        var results = [];

        forEach(theCollection, function(elem) {
            if (theFunction(elem)) {
                results.push(elem);
            }
        });

        return results;
    };

    var map = function(theCollection, theFunction) {
        var results = [];

        forEach(theCollection, function(elem) {
            results.push(theFunction(elem));
        });

        return results;
    };

    var throttle = function(theFunc, theWait) {

        var throttled = false;

        var resetThrottle = function() {
            throttled = false;
        };

        return function() {
            if (!throttled) {
                throttled = true;
                setTimeout(resetThrottle, theWait);
                theFunc();
            }
        }
    };

    var uuid = function() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(theChar) {
            var rand = Math.random() * 16 | 0;
            var val = (theChar == "x") ? rand : (rand & 0x3 | 0x8);
            return val.toString(16);
        });
    };

    var kassoConfig = (function() {
        var config;
        var CONFIG_URL = "/KASSO/config";


        return {
            getConfig: function(application) {

                if (config) return config;
                else {
                    jq.ajax({
                        cache: false,
                        crossDomain: false,
                        type: "GET",
                        url: CONFIG_URL + "?app=" + application,
                        async: false,
                        success: function(data) {
                            config = data;
                        },
                        error: function(data) {
                            config = new Object();
                            config.idlePeriod = 28; //default
                        }
                    });
                    if (!config) {
                        config = new Object();
                        config.idlePeriod = 28; //default
                    }
                    return config;
                }
                // else show some error that it isn't loaded yet;
            }
        };
    })();

    var kassoComparators = {

        applicationComparator: function(a, b) {
            return a.localeCompare(b);
        }
    };

    var kassoInternal = new function() {

        var applicationName;

        return {
            activityOccurred: throttle(function() {
                var currentTime = createDate().getTime();
                kassoStorage.setLastActivity(currentTime);
                kassoStorage.setLastActiveApplication(applicationName);
            }, seconds(15)),
            shouldHideLogoutDialog: function(newValue) {
                return kassoStorage.setShouldHideLogoutDialog(newValue);
            },
            logoutInitiated: function() {
                if (kassoStorage.setShouldHideLogoutDialog()) {
                    kassoInternal.logoutOccurred();
                } else {
                    var currentTime = createDate().getTime();
                    kassoStorage.setLogoutInitiated(currentTime);
                }
            },
            logoutOccurred: function() {
                var currentTime = createDate().getTime();
                kassoStorage.removeLogoutInitiated();
                kassoStorage.setLogout(currentTime);
            },
            logoutCancelled: function() {
                kassoStorage.removeLogoutInitiated();
            },
            getActiveApplications: function() {
                return kassoStorage.getApplications().sort(kassoComparators.applicationComparator);
            },
            startup: function(application, callBack, bindTargets) {
                applicationName = application;
                kassoStorage.removeLogout();
                kassoMonitoring.startMonitoring(application, callBack);
                kassoUiEvents.bindEvents(bindTargets);
                kassoApplicationListeners.registerListeners(callBack);
                kassoStorage.setUuid(uuid());
                kassoInternal.activityOccurred();
            },
            shutdown: function() {
                kassoApplicationListeners.unregisterListeners();
                kassoMonitoring.stopMonitoring();
                kassoUiEvents.unbindEvents();
            },
            getTimeoutValue: function(application) {
                var configuration = kassoConfig.getConfig(application);
                if (configuration != undefined && configuration != null) {
                    return configuration.idlePeriod + 2;
                } else {
                    return 30;
                }
            }
        };
    };

    var kassoStorage = new function() {

        var LAST_ACTIVITY_KEY = "kasso.last.activity";
        var LOGOUT_INITIATED_KEY = "kasso.logout.initiated";
        var LOGOUT_KEY = "kasso.logout";
        var APPLICATION_KEY = "kasso.application.";
        var HIDE_LOGOUT_DIALOG_KEY = "kasso.should.hide.logout.dialog";
        var UUID_KEY = "kasso.uuid";
        var LAST_ACTIVE_APPLICATION_KEY = "kasso.last.active.application";

        var deriveDomain = function(domain) {
            return domain;
        };

        var isHostName = function(domain) {
            //If it has only decimals in it, it's not a "host-name" so look for any non-decimal character and see if we find one
            return /\D/.test(domain.replace(/\./g, ""));
        };

        var getDomain = function(domain) {
            if (isHostName(domain)) {
                return deriveDomain(domain);
            }

            return "";
        };

        var createCookieOptions = function(optionalExpiryDate) {
            var options = {
                domain: getDomain(document.domain),
                path: "/"
            };

            if (optionalExpiryDate) {
                options.expires = optionalExpiryDate;
            }

            return options;
        };

        var getCookieOptions = function(optionalExpiryDate) {
            return createCookieOptions(optionalExpiryDate);
        };

        var setCookie = function(key, value, optionalExpiryDate) {
            jq.cookie(key, value, createCookieOptions(optionalExpiryDate));
        };

        var getCookie = function(key) {
            return jq.cookie(key);
        };

        var getAndRefreshCookie = function(key, expires) {
            var val = getCookie(key);
            setCookie(key, val, expires);
            return val;
        };

        var removeCookie = function(key) {
            jq.removeCookie(key, getCookieOptions());
        };

        var convertTruthy = function(value) {
            if (value) {
                return "true";
            } else {
                return "";
            }
        };

        return {
            getLastActivity: function() {
                var lastActiveTime = getCookie(LAST_ACTIVITY_KEY);
                //This refreshes the cookie's time-to-live so it doesn't expire and simulate inactivity
                //So why don't you just set the cookie's expiry to 30 minutes in the future or so?
                //Well, do you want to have to clean-up cookies after a browser that has closed before your code executes?
                //No, no I don't
                //Exactly
                kassoStorage.setLastActivity(lastActiveTime);
                return +lastActiveTime || 0;
            },
            setLastActivity: function(lastActiveTime) {
                setCookie(LAST_ACTIVITY_KEY, lastActiveTime, createDate(seconds(120)));
            },
            setShouldHideLogoutDialog: function(newValue) {
                var cookieValueToSetOrRefresh;
                if (newValue != undefined) {
                    cookieValueToSetOrRefresh = convertTruthy(newValue);
                } else {
                    cookieValueToSetOrRefresh = getCookie(HIDE_LOGOUT_DIALOG_KEY);
                }

                setCookie(HIDE_LOGOUT_DIALOG_KEY, cookieValueToSetOrRefresh, createDate(years(1)));
                if (cookieValueToSetOrRefresh) {
                    return true;
                } else {
                    return false;
                }
            },
            getLogoutInitiated: function() {
                var logoutInitiatedTime = getCookie(LOGOUT_INITIATED_KEY);
                kassoStorage.setLogoutInitiated(logoutInitiatedTime);
                return +logoutInitiatedTime || 0;
            },
            setLogoutInitiated: function(whenInitiated) {
                setCookie(LOGOUT_INITIATED_KEY, whenInitiated, createDate(seconds(15)));
            },
            removeLogoutInitiated: function() {
                removeCookie(LOGOUT_INITIATED_KEY);
            },
            getLogout: function() {
                return getCookie(LOGOUT_KEY);
            },
            setLogout: function(whenLogoutOccurred) {
                setCookie(LOGOUT_KEY, whenLogoutOccurred, createDate(seconds(120)));
            },
            removeLogout: function() {
                removeCookie(LOGOUT_KEY);
            },
            addApplication: function(application) {
                setCookie(APPLICATION_KEY + application, true, createDate(seconds(5)));
            },
            getApplications: function() {
                var regExp = new RegExp("^" + APPLICATION_KEY);
                var applicationCookies = filter(getCookie(), function(cookie) {
                    return regExp.test(cookie.key);
                });
                return map(applicationCookies, function(cookie) {
                    return cookie.key.replace(regExp, "");
                });
            },
            getUuid: function() {
                var uuid = getCookie(UUID_KEY);
                return uuid || "";
            },
            setUuid: function(theValue) {
                setCookie(UUID_KEY, theValue);
            },
            setLastActiveApplication: function(application) {
                setCookie(LAST_ACTIVE_APPLICATION_KEY, application);
            }
        }
    };

    var kassoUiEvents = new function() {

        var silenceInWarningPeriod = function(theFunc) {
            return function() {
                if (!kassoActivityMonitor.inWarningPeriod()) {
                    theFunc();
                }
            };
        };

        var events = [createEventDefinition("click", silenceInWarningPeriod(kassoInternal.activityOccurred)),
            createEventDefinition("keypress", silenceInWarningPeriod(kassoInternal.activityOccurred)),
            createEventDefinition("scroll", silenceInWarningPeriod(kassoInternal.activityOccurred))
        ];

        var allTargets = [];

        var generateAndRecordTargets = function(bindTargets) {

            var targets = [];

            if (bindTargets) {
                targets = targets.concat(bindTargets);
            }

            allTargets = targets;
            return targets;
        };

        return {
            bindEvents: function(bindTargets) {
                forEach(generateAndRecordTargets(bindTargets), function(target) {
                    forEach(events, function(event) {
                        jq(target).on(event.eventName, event.eventFunction);
                    });
                });
            },
            unbindEvents: function() {
                forEach(allTargets, function(target) {
                    forEach(events, function(event) {
                        jq(target).off(event.eventName, event.eventFunction);
                    });
                });
            }
        }
    };

    var kassoCallbackEvents = {

        activityOccurredCallback: function(callBack) {
            return function() {
                if (callBack.notifyActive) {
                    callBack.notifyActive();
                }
            };
        },
        inactivityOccurredCallback: function(callBack) {

            return function(event, timeout) {
                if (callBack.notifyInactive) {
                    callBack.notifyInactive(timeout);
                }
            };
        },
        logoutInitiatedCallback: function(callBack) {
            return function() {
                if (callBack.notifyLogoutInitiated) {
                    callBack.notifyLogoutInitiated();
                }
            };
        },
        logoutCancelledCallback: function(callBack) {
            return function() {
                if (callBack.notifyLogoutCancelled) {
                    callBack.notifyLogoutCancelled();
                }
            };
        },
        logoutOccurredCallback: function(callBack) {
            return function() {
                if (callBack.notifyLogout) {
                    callBack.notifyLogout();
                }
            };
        },
        heartBeatSuccessCallback: function(callBack) {
            return function() {
                if (callBack.notifyHeartBeatSuccess) {
                    callBack.notifyHeartBeatSuccess();
                }
            };
        },
        heartBeatErrorCallback: function(callBack) {
            return function() {
                if (callBack.notifyHeartBeatError) {
                    callBack.notifyHeartBeatError();
                }
            }
        }
    };

    var kassoApplicationEventDefinitions = {

        activityOccurredEvent: createEventDefinition("kasso.activity.occurred", kassoCallbackEvents.activityOccurredCallback),
        inactivityOccurredEvent: createEventDefinition("kasso.inactivity.occurred", kassoCallbackEvents.inactivityOccurredCallback),
        logoutInitiatedEvent: createEventDefinition("kasso.logout.initiated", kassoCallbackEvents.logoutInitiatedCallback),
        logoutCancelledEvent: createEventDefinition("kasso.logout.cancelled", kassoCallbackEvents.logoutCancelledCallback),
        logoutOccurredEvent: createEventDefinition("kasso.logout.occurred", kassoCallbackEvents.logoutOccurredCallback),
        heartBeatSuccessEvent: createEventDefinition("kasso.heart.beat.success", kassoCallbackEvents.heartBeatSuccessCallback),
        heartBeatErrorEvent: createEventDefinition("kasso.heart.beat.error", kassoCallbackEvents.heartBeatErrorCallback)
    };

    var kassoApplicationEvents = {

        events: map(kassoApplicationEventDefinitions, function(elem) {
            return elem.value;
        }),
        getPublicEventNames: function() {
            var events = {};
            forEach(kassoApplicationEventDefinitions, function(elem) {
                events[elem.key] = elem.value.eventName;
            });

            return events;
        }
    };

    var kassoApplicationListeners = new function() {

        var listenerTarget = {};
        var notificationTargets = [listenerTarget, window];

        var forEachTarget = function(theFunc) {
            forEach(notificationTargets, theFunc);
        };

        return {

            registerListeners: function(callBack) {
                forEach(kassoApplicationEvents.events, function(event) {
                    jq(listenerTarget).on(event.eventName, event.eventFunction(callBack));
                });
            },
            unregisterListeners: function() {
                forEach(kassoApplicationEvents.events, function(event) {
                    jq(listenerTarget).off(event.eventName);
                });
            },
            fireActivityOccurred: function() {
                forEachTarget(function(target) {
                    jq(target).trigger(kassoApplicationEventDefinitions.activityOccurredEvent.eventName);
                });
            },
            fireInactivityOccurred: function(countdownPeriod) {
                forEachTarget(function(target) {
                    jq(target).trigger(kassoApplicationEventDefinitions.inactivityOccurredEvent.eventName, {
                        milliseconds: countdownPeriod,
                        dateInFuture: createDate(countdownPeriod)
                    });
                });
            },
            fireLogoutInitiated: function(activeApplications) {
                forEachTarget(function(target) {
                    jq(target).trigger(kassoApplicationEventDefinitions.logoutInitiatedEvent.eventName, activeApplications);
                });
            },
            fireLogoutOccurred: function() {
                forEachTarget(function(target) {
                    jq(target).trigger(kassoApplicationEventDefinitions.logoutOccurredEvent.eventName);
                });
            },
            fireLogoutCancelled: function() {
                forEachTarget(function(target) {
                    jq(target).trigger(kassoApplicationEventDefinitions.logoutCancelledEvent.eventName);
                })
            },
            fireHeartBeatSuccess: function() {
                forEachTarget(function(target) {
                    jq(target).trigger(kassoApplicationEventDefinitions.heartBeatSuccessEvent.eventName);
                });
            },
            fireHeartBeatError: function() {
                forEachTarget(function(target) {
                    jq(target).trigger(kassoApplicationEventDefinitions.heartBeatErrorEvent.eventName);
                });
            }
        };
    };

    var kassoHeartBeatMonitor = new function() {

        var createHeartBeatFunction = function(callBack) {

            var hasHeartBeatBehavior = function() {
                return ((callBack.heartBeatUrl != undefined));
            };

            if (hasHeartBeatBehavior(callBack)) {

                var ajaxSettings = {
                    cache: false,
                    async: true,
                    crossDomain: false,
                    timeout: seconds(10),
                    success: kassoApplicationListeners.fireHeartBeatSuccess,
                    error: kassoApplicationListeners.fireHeartBeatError
                };

                return function() {
                    jq.ajax(callBack.heartBeatUrl, ajaxSettings);
                };

            } else {

                var ajaxSettings = {
                    cache: false,
                    async: true,
                    timeout: seconds(10),
                    success: kassoApplicationListeners.fireHeartBeatSuccess,
                    error: kassoApplicationListeners.fireHeartBeatError
                };

                return function() {
                    jq.ajax("/KASSO/heartbeat/keep-alive", ajaxSettings).done(function(data) {
                        if (data.externalLogout) {
                            kassoApplicationListeners.fireLogoutOccurred();
                            kassoInternal.shutdown();
                        } else {
                            kassoStorage.setLastActivity(data.externalActivityTimestamp);
                            if(data.externalActivityTimestamp && data.externalActivityTimestamp !== 0) {
                                kassoActivityMonitor.setLastObservedActivityTime(data.externalActivityTimestamp);
                            }
                        }
                    });
                }
            }
        };

        return {

            createMonitor: function(application, callBack) {

                return {

                    monitor: createHeartBeatFunction(callBack)
                };
            }
        }
    };

    var kassoActivityMonitor = new function() {
        //wasLastActive is used to try and prevent spamming client-apps with notifications for the same state, instead
        // of only sending notifications on state changes
        //e.g. (active -> active -> active) vs (active -> inactive -> active)

        var EXPECTED_INTERVAL_EXECUTION_THRESHOLD = seconds(5);
        var IDLE_PERIOD = minutes(28);
        var TIMEOUT_PERIOD = minutes(2);
        var wasLastActive = false;
        var inWarningPeriod = false;
        var lastIntervalExecutionTime = 0;
        var lastObservedActivityTime = 0;
        var logoffTimer;





        var shouldNotifyInactive = function() {
            if (wasLastActive) {
                wasLastActive = false;
                return true;
            }

            return false;
        };

        var shouldNotifyActive = function() {
            if (!wasLastActive) {
                wasLastActive = true;
                return false;
            }

            return true;
        };

        var timerExists = function() {
            return logoffTimer != undefined;
        };

        var startLogoffTimer = function(timeout) {
            if (!timerExists()) {
                logoffTimer = setTimeout(kassoInternal.logoutOccurred, timeout);
            }
        };

        var stopLogoffTimer = function() {
            if (timerExists()) {
                clearTimeout(logoffTimer);
                logoffTimer = undefined;
            }
        };

        var restoreStateIfNecessary = function(lastActive) {

            var currentTime = createDate().getTime();

            //If we were delayed/paused/suspended AND the last-activity cookie has expired/disappeared, restore it to
            // the last value we saw
            if (((currentTime - lastIntervalExecutionTime) > EXPECTED_INTERVAL_EXECUTION_THRESHOLD) && (!lastActive)) {
                kassoStorage.setLastActivity(lastObservedActivityTime);
            } else {
                //Keep track of the most-recently-seen value for the next time we've been suspended, but only if we haven't
                //restored an older value
                lastObservedActivityTime = lastActive;
            }

            lastIntervalExecutionTime = currentTime;
            return lastObservedActivityTime;
        };
        return {
            inWarningPeriod: function() {
                return inWarningPeriod;
            },
            setLastObservedActivityTime : function(newActivityTime) {
                lastObservedActivityTime = newActivityTime;
            },
            createMonitor: function(application) {
                return {
                    monitor: function() {

                        if (kassoConfig != undefined && kassoConfig != null) {
                            var configuration = kassoConfig.getConfig(application);
                            IDLE_PERIOD = minutes(configuration.idlePeriod);
                        }

                        var mustBeActiveSince = createDate(-IDLE_PERIOD).getTime();
                        var lastActive = restoreStateIfNecessary(kassoStorage.getLastActivity());

                        if (mustBeActiveSince >= lastActive) {
                            inWarningPeriod = true;
                            if (shouldNotifyInactive()) {
                                kassoApplicationListeners.fireInactivityOccurred(TIMEOUT_PERIOD);
                            }
                            startLogoffTimer(TIMEOUT_PERIOD);
                        } else {
                            stopLogoffTimer();
                            inWarningPeriod = false;
                            if (!shouldNotifyActive()) {
                                kassoApplicationListeners.fireActivityOccurred();
                            }
                        }
                    } //monitor
                } // return
            } //createMonitor
        } //return
    };

    var kassoInitiatedLogoutMonitor = new function() {

        var didNotifyLogoutInitiated = false;

        return {

            monitor: function() {

                var logoutInitiatedTime = kassoStorage.getLogoutInitiated();

                if ((logoutInitiatedTime != 0) && (!didNotifyLogoutInitiated)) {
                    //Pending logout happened but we haven't notified the application yet
                    kassoApplicationListeners.fireLogoutInitiated(kassoInternal.getActiveApplications());
                    didNotifyLogoutInitiated = true;
                } else if ((logoutInitiatedTime == 0) && (didNotifyLogoutInitiated)) {
                    //Pending logout cancelled, and we've already told the app it has been initiated, so tell them it's cancelled
                    //and reset the didNotifyLogoutInitiated flag so we can tell the app again in the future
                    didNotifyLogoutInitiated = false;
                    kassoApplicationListeners.fireLogoutCancelled();
                } else {
                    //Nothing
                }
            }
        }
    };

    var kassoLogoutMonitor = new function() {

        return {

            monitor: function() {
                var didLogoutOccur = kassoStorage.getLogout();
                if (didLogoutOccur) {
                    kassoApplicationListeners.fireLogoutOccurred();
                    kassoInternal.shutdown();
                }
            }
        };
    };

    var kassoActiveApplicationMonitor = {

        createMonitor: function(application) {

            return {
                monitor: function() {
                    kassoStorage.addApplication(application);
                }
            };
        }
    };

    var kassoThirdPartyMonitor = new function() {

        var CHECK_IN_URL = "/KASSO/whatever"; //TODO fill in with actual value

        var successFunction = function() {

        };

        var errorFunction = function() {

        };

        var ajaxSettings = {
            cache: false,
            async: true,
            crossDomain: false,
            timeout: seconds(10),
            success: successFunction,
            error: errorFunction
        };

        return {

            monitor: function() {
                //                jq.ajax(CHECK_IN_URL, ajaxSettings);
            }
        };
    };

    var kassoMonitoring = new function() {

        var createMonitor = function(theFunction, theIntervalPeriod) {

            return {
                "monitorFunction": theFunction.monitor,
                "intervalPeriod": theIntervalPeriod
            };
        };

        var monitors;

        return {
            startMonitoring: function(application, callBack) {

                monitors = [createMonitor(kassoHeartBeatMonitor.createMonitor(application, callBack), minutes(1)),
                    createMonitor(kassoActivityMonitor.createMonitor(application), seconds(.5)),
                    createMonitor(kassoInitiatedLogoutMonitor, seconds(.5)),
                    createMonitor(kassoLogoutMonitor, seconds(.5)),
                    createMonitor(kassoActiveApplicationMonitor.createMonitor(application), seconds(.5)),
                    createMonitor(kassoThirdPartyMonitor, minutes(1))
                ];

                forEach(monitors, function(monitor) {
                    monitor.interval = setInterval(monitor.monitorFunction, monitor.intervalPeriod);
                });
            },
            stopMonitoring: function() {
                forEach(monitors, function(monitor) {
                    clearInterval(monitor.interval);
                });
            }
        };
    };

    var kasso = {

        startup: function(application, callBack, bindTargets) {
            kassoInternal.startup(application, callBack, bindTargets);
        },
        activityOccurred: function() {
            kassoInternal.activityOccurred();
        },
        shouldHideLogoutDialog: function(newValue) {
            return kassoInternal.shouldHideLogoutDialog(newValue);
        },
        logoutInitiated: function() {
            kassoInternal.logoutInitiated();
        },
        logoutOccurred: function() {
            kassoInternal.logoutOccurred();
        },
        logoutCancelled: function() {
            kassoInternal.logoutCancelled();
        },
        getActiveApplications: function() {
            return kassoInternal.getActiveApplications();
        },
        shutdown: function() {
            kassoInternal.shutdown();
        },
        getTimeoutValue: function(application) {
            return kassoInternal.getTimeoutValue(application);
        },
        events: kassoApplicationEvents.getPublicEventNames()
    };

    return kasso;
}));