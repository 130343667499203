// $(function() {
//     previewIt();
// });

$(window).resize(function() {
    previewIt();
});

function previewIt() {
    var wh = $(window).height() - 210;
    if ($('.pres-prev').hasClass('_expanded')) {
        $('.pres-prev').css('width', '80%');
        $('.pres-prev').css('max-width', '992px');
        $('.pres-prev').css('margin-left', '-' + $('.pres-prev').width() / 2 + 'px');
        var wf = $('.pres-prev').width() / 612;
        $('.pres-prev').css('font-size', (wf * 10) + 'px');
    } else {
        $('.pres-prev').css('max-height', wh + 'px');
        $('.pres-prev').css('max-width', wh * 0.77272727 + 'px');
        $('.pres-prev').css('margin-left', '0');
        var wf = $('.pres-prev').width() / 612;
        $('.pres-prev').css('font-size', (wf * 10) + 'px');
    }

    $('.pager-row').css('max-width', wh * 0.77272727 + 'px');
}
